import type { AxiosInstance } from 'axios'
import type { ICompany, IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'expenseReports'

const filter = (axios: IFactoryFunctionRequest<paginate<Array<Pick<ICompany, '_id' | 'display'>>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/filter`,
    query: options,
  })
}

const showAvailableReport = (axios: IFactoryFunctionRequest<paginate<Array<any>>, undefined>) => (query = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    query: query,
  })
}
const showAReport = (axios: IFactoryFunctionRequest<any, any>) => (reportId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/reportPage/${reportId}`,
  })
}
const exportReportForComptability = (axios: IFactoryFunctionRequest<any, any>) => ({}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/exportReportForComptability`,
    responseType: 'blob',
  })
}

const createNewExpenseReport = (axios: IFactoryFunctionRequest<{ _id: string }, undefined>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data,
  })
}

const updateExpenseSettings = (axios: IFactoryFunctionRequest<{ _id: string }, undefined>) => (reportId: string, data: {display?: string}) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${reportId}`,
    data,
  })
}

const deleteMyReportAndUnlinkExpenses = (axios: IFactoryFunctionRequest<{ _id: string }, undefined>) => (reportId: string) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/delete/${reportId}`,
  })
}

const sendForValidation = (axios: IFactoryFunctionRequest<any, any>) => ({ reportId }: { reportId?: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/sendForValidation/${reportId}`,
  })
}

const cancelValidation = (axios: IFactoryFunctionRequest<any, any>) => ({ reportId }: { reportId?: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/cancelValidation/${reportId}`,
  })
}

const validateReport = (axios: IFactoryFunctionRequest<any, any>) => ({ reportId }: { reportId?: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/validateReport/${reportId}`,
  })
}

const needForUpdate = (axios: IFactoryFunctionRequest<any, any>) => ({ reportId }: { reportId?: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/needForUpdate/${reportId}`,
  })
}

const refoundReport = (axios: IFactoryFunctionRequest<any, any>) => ({ reportId }: { reportId?: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/refoundReport/${reportId}`,
  })
}

export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
  createNewExpenseReport: createNewExpenseReport(useAxios(axios)),
  showAReport: showAReport(useAxios(axios)),
  showAvailableReport: showAvailableReport(useAxios(axios)),
  updateExpenseSettings: updateExpenseSettings(useAxios(axios)),
  deleteMyReportAndUnlinkExpenses: deleteMyReportAndUnlinkExpenses(useAxios(axios)),
  sendForValidation: sendForValidation(useAxios(axios)),
  cancelValidation: cancelValidation(useAxios(axios)),
  validateReport: validateReport(useAxios(axios)),
  refoundReport: refoundReport(useAxios(axios)),
  needForUpdate: needForUpdate(useAxios(axios)),
  exportReportForComptability: exportReportForComptability(useAxios(axios))

})
