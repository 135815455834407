import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 1

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.hr.isAccess
}
export const guardResourceProfile = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.resourceProfileAccess ? user.profile.modules.hr.resourceProfileAccess : { name: 'Home' }
}

export const guardHrAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const accessModule = canUserAccessModule(user)
  return accessModule || { name: 'Home' }
}

export const guardHrStatsAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.statsAccess ? user.profile.modules.hr.statsAccess : { name: 'Home' }
}

export const guardHrCreateResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.createResourceAccess ? user.profile.modules.hr.createResourceAccess : { name: 'Home' }
}
