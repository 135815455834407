import type { RouteRecordRaw } from 'vue-router'
import { guardCandidateBase, guardCreateCandidate, guardJobBoard, guardJobBoardAdmin, guardPipe, guardRecruitmentAccess, guardRecruitmentStatsAccess, guardScreening, guardSettingsAccess } from '@/config/router/navigationsGuards/guardRecruitment'

const MODULE_NAME = 'RECRUITMENT'
const MODULE_ID = 4

const recruitmentRoutes: Array<RouteRecordRaw> = [

  {
    path: '/recruitment/career',
    meta: {
      requiresAuth: false,
    },
    beforeEnter: [],
    children: [
      {
        path: ':firmId',
        name: 'Careers',
        component: () => import('@/views/recruitment/career/Career.vue'),
        meta: {
          requiresAuth: false,
          navbarNotRequired: true,
        },
      },
      {
        path: ':firmId/:jobId',
        name: 'CareerJob',
        component: () => import('@/views/recruitment/career/Job.vue'),
        meta: {
          requiresAuth: false,
          navbarNotRequired: true,
        },
      },
    ],
  },
  {
    path: '/recruitment',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardRecruitmentAccess],
    children: [
      {
        path: '',
        name: 'Screening',
        component: () => import('@/views/recruitment/Screening.vue'),
        beforeEnter: [guardScreening],
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
          titleTranslationKey: 'pageTitle.screening',
        },
      },
      {
        path: 'create',
        name: 'CreateCandidate',
        component: () => import('@/views/recruitment/CreateCandidate.vue'),
        beforeEnter: [guardCreateCandidate],
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
          titleTranslationKey: 'pageTitle.createCandidate',
        },
      },
      {
        path: 'meetings',
        name: 'Meetings',
        component: () => import('@/views/recruitment/Meetings.vue'),
        beforeEnter: [],
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
          titleTranslationKey: 'pageTitle.meetings',
        },
      },
      {
        path: 'career/administration',
        name: 'JobBoardAdmin',
        component: () => import('@/views/recruitment/career/Administration.vue'),
        beforeEnter: [guardJobBoardAdmin],
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
          titleTranslationKey: 'pageTitle.jobBoardAdministration',
        },
      },
      {
        path: 'process',
        name: 'RecruitmentProcess',
        component: () => import('@/views/recruitment/recruitmentProcess.vue'),
        beforeEnter: [guardPipe],
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
          titleTranslationKey: 'pageTitle.recruitmentProcess',
        },
      },
      {
        path: 'interviews/:processId',
        meta: {
          requiresAuth: true,
          navbarNotRequired: false,
        },
        children: [
          {
            path: '',
            name: 'RecruitmentProcessInterviews',
            component: () => import('@/views/recruitment/interviews/Interviews.vue'),
          },
          {
            path: 'interview/:interviewId/:recruiterId', // not resource But object that container recruiterId
            name: 'InterviewEvaluation',
            component: () => import('@/views/recruitment/interviews/Evaluation.vue'),
            meta: {
              requiresAuth: true,
              navbarNotRequired: false,
            },
          },
        ],
      },
      {
        path: 'recruitmentDatabase',
        name: 'RecruitmentDatabase',
        component: () => import('@/views/recruitment/CandidateBase.vue'),
        beforeEnter: [guardCandidateBase],
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.candidateBase',
        },
      },
      {
        path: 'profile/:resourceId',
        name: 'RecruitmentProfile',
        component: () => import('@/views/recruitment/recruitmentProfile.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'stats',
        name: 'RecruitmentStats',
        component: () => import('@/views/recruitment/Stats.vue'),
        meta: {
          titleTranslationKey: 'pageTitle.hrStats',
        },
        beforeEnter: [guardRecruitmentStatsAccess],
      },
      {
        path: 'settings',
        name: 'RecruitmentSettings',
        component: () => import('@/views/recruitment/Settings.vue'),
        meta: {
          titleTranslationKey: 'pageTitle.recruitmentSettings',
        },
        beforeEnter: [guardSettingsAccess],
      },

    ],
  },
]

export default recruitmentRoutes.map(route => ({
  ...route,
  meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID },
}))
